<script setup>
</script>
<template>
    <q-item clickable class="rounded" active-class="active">
        <q-item-section>
            <q-item-label>
                <slot name="label" />
            </q-item-label>
        </q-item-section>
        <q-item-section avatar>
            <slot name="icon" />
        </q-item-section>
    </q-item>
</template>

<style scoped>
.active {
    background-color: #b1b1b1;
    color: #184167;
}
</style>