<script setup lang="ts">
import SideBarBtn from "@/components/buttons/SideBarBtn.vue";
import MenuItem from "@/components/app/MenuItem.vue";
import LazyHomeIcon from '@/assets/icons/HomeIcon.vue'
import LazyCreditCardIcon from '@/assets/icons/CreditCardIcon.vue'
import LazyUserIcon from '@/assets/icons/UserIcon.vue'
import LazySettingsIcon from '@/assets/icons/SettingsIcon.vue'
import LazyLogoutIcon from '@/assets/icons/LogoutIcon.vue'
import LazyCalendarIcon from '@/assets/icons/CalendarIcon.vue'
import LazyBedIcon from "@/assets/icons/BedIcon.vue"
import BellIcon from "@/assets/icons/BellIcon.vue"

//HOOKS
const supabase = useSupabaseClient()
const router = useRouter()

//DATA
const visible = ref(false);
const sideBar = ref(false);

//METHODS
const signOut = async () => {
    const { error } = await supabase.auth.signOut()
    if (error) console.error("ERROR", error);
    else router.push('/')
}

//LIFECYCLE

</script>

<template>

    <div class="flex justify-between items-center w-full bg-[#F3F4F6]">
        <button class="">
            <BellIcon class="text-2xl" />
        </button>
        <Logo class="w-14" />
        <SideBarBtn @click="visible = true" class="text-[#2C3E50]" />
    </div>

    <q-dialog v-model="visible" maximized transition-show="slide-left" transition-hide="slide-right">
        <q-card class="h-full">
            <header class="flex justify-between py-4 px-6 bg-[#2B2B2B] text-white">
                <button></button>
                <LogoLightLogo class="w-14" />
                <SideBarBtn @click="visible = false" />
            </header>
            <q-card-section class="items-center h-full bg-[#2B2B2B] text-[#D3D3D3] font-light">
                <q-list class="w-full h-full">
                    <MenuItem :to="{ path: '/pms/dashboard' }">
                    <template #label>
                        Accueil
                    </template>
                    <template #icon>
                        <LazyHomeIcon />
                    </template>
                    </MenuItem>
                    <q-separator spaced />
                    <MenuItem :to="{ path: '/pms/bookings' }">
                    <template #label>
                        Réservations
                    </template>
                    <template #icon>
                        <LazyCalendarIcon class="text-2xl" />
                    </template>
                    </MenuItem>
                    <MenuItem :to="{ path: '/pms/rooms' }">
                    <template #label>
                        Chambres
                    </template>
                    <template #icon>
                        <LazyBedIcon class="text-2xl" />
                    </template>
                    </MenuItem>
                    <q-separator spaced />
                    <MenuItem :to="{ path: '/pms/accounting' }">
                    <template #label>
                        Comptabilité
                    </template>
                    <template #icon>
                        <LazyCreditCardIcon class="text-2xl" />
                    </template>
                    </MenuItem>
                    <MenuItem :to="{ path: '/pms/clients' }" disable>
                    <template #label>
                        Clients
                    </template>
                    <template #icon>
                        <LazyUserIcon class="text-2xl" />
                    </template>
                    </MenuItem>
                    <q-separator spaced />
                    <MenuItem :to="{ path: '/pms/settings' }">
                    <template #label>
                        Paramétres
                    </template>
                    <template #icon>
                        <LazySettingsIcon class="text-2xl" />
                    </template>
                    </MenuItem>
                    <q-separator spaced />
                    <div class="flex flex-col justify-end flex-1" @click="signOut">
                        <MenuItem>
                        <template #label>
                            Déconnexion
                        </template>
                        <template #icon>
                            <LazyLogoutIcon class="text-2xl" />
                        </template>
                        </MenuItem>
                    </div>
                </q-list>
            </q-card-section>
        </q-card>
    </q-dialog>
</template>
