<script setup lang="ts">
import MenuItem from './MenuItem.vue';
import LazySettingsIcon from '@/assets/icons/SettingsIcon.vue'
import LazyUserIcon from '@/assets/icons/UserIcon.vue'
import LazyCalendarIcon from '@/assets/icons/CalendarIcon.vue'
import LazyHomeIcon from '@/assets/icons/HomeIcon.vue'
import LazyLogoutIcon from '@/assets/icons/LogoutIcon.vue'
import LazyCreditCardIcon from '@/assets/icons/CreditCardIcon.vue'
import LazyBedIcon from "@/assets/icons/BedIcon.vue"

//HOOKS
const supabase = useSupabaseClient()
const router = useRouter()

//METHODS
const signOut = async () => {
    const { error } = await supabase.auth.signOut()
    if (error) console.error("ERROR", error);
    else router.push('/')
}

</script>

<template>
    <q-list class="w-full h-full active:text-[#34495E] flex flex-col bg-[#2B2B2B] text-[#D3D3D3] font-light" padding>
        <div class="px-4">
            <MenuItem :to="{ path: '/pms/dashboard' }">
            <template #label>
                Accueil
            </template>
            <template #icon>
                <LazyHomeIcon class="
                    text-2xl font-bold" />
            </template>
            </MenuItem>
        </div>

        <q-separator spaced />

        <div class="px-4">
            <MenuItem :to="{ path: '/pms/bookings' }">
            <template #label>
                Réservations
            </template>
            <template #icon>
                <LazyCalendarIcon class="
        text-2xl" />
            </template>
            </MenuItem>
            <MenuItem :to="{ path: '/pms/rooms' }">
            <template #label>
                Chambres
            </template>
            <template #icon>
                <LazyBedIcon class="
                    text-2xl" />
            </template>
            </MenuItem>

        </div>
        <q-separator spaced />

        <div class="px-4">
            <MenuItem :to="{ path: '/pms/accounting' }">
            <template #label>
                Comptabilité
            </template>
            <template #icon>
                <LazyCreditCardIcon class=" text-2xl" />
            </template>
            </MenuItem>

            <MenuItem disable>
            <template #label>
                <q-tooltip>
                    Bientôt disponible
                </q-tooltip>
                Clients
            </template>
            <template #icon>
                <LazyUserIcon class=" text-2xl" />
            </template>
            </MenuItem>
        </div>


        <q-separator spaced />

        <div class="px-4">
            <MenuItem :to="{ path: '/pms/settings' }">
            <template #label>
                Paramétres
            </template>
            <template #icon>
                <LazySettingsIcon class=" text-2xl" />
            </template>
            </MenuItem>
        </div>

        <div class="flex flex-col px-4 flex-1">
            <MenuItem @click="signOut">
            <template #label>
                Déconnexion
            </template>
            <template #icon>
                <LazyLogoutIcon class=" text-2xl" />
            </template>
            </MenuItem>
        </div>
    </q-list>

</template>