<script setup>
import SideMenu from '../components/app/SideMenu.vue'
import LightLogo from "@/components/logo/LightLogo.vue"
import BellIcon from "@/assets/icons/BellIcon.vue"
import { useLoadingStore } from '~/stores/loading.store';


//META

//HOOKS
const user = useSupabaseUser()
const loadingStore = useLoadingStore()
const { loading } = storeToRefs(loadingStore)

//DATA
const loggedOut = ref(false)
const notificationDrawer = ref(false)

//METHODS


//WATCHER
watch(user, () => {
    if (!user.value) loggedOut.value = true
})


//LIFECYCLE
onMounted(async () => {

})
</script>
<template>
    <Dialog v-model:visible="loggedOut" modal :closable="false" header="Vous avez été délogué"
        :style="{ width: '25rem' }">
        <div class="flex justify-center">
            <NuxtLink to="/signin">
                <ButtonsPrimaryBtn>
                    Connexion
                </ButtonsPrimaryBtn>
            </NuxtLink>
        </div>
    </Dialog>

    <div id="appLayout" class="grid sm:flex sm:flex-row">
        <AppLoading v-if="loading" />
        <header class="desktop-hidden flex justify-between py-4 px-6 min-h-[2.5em] bg-[#F3F4F6]">
            <AppTopMenu class="bg-[#F3F4F6]" />
        </header>
        <aside class="phone-hidden w-[16em] border-r-[1px] border-gray-300 bg-[#2B2B2B] max-h-max fixed h-full z-10">
            <LightLogo />
            <SideMenu />
        </aside>
        <main class="body-text py-3 flex-1 min-h-screen bg-gray-100 lg:px-10 lg:py-6 md:ml-[223px]">
            <header class="flex flex-row mb-4 justify-end  phone-hidden h-[1.5em]">
                <button>
                    <BellIcon @click="notificationDrawer = true" />
                </button>
            </header>
            <Drawer v-model:visible="notificationDrawer" header="Notifications" position="right" class="phone-hidden">

            </Drawer>
            <slot />
        </main>
    </div>
</template>